#kidsInfoDrawerWrapper {
  padding-bottom: 50px;
  .content {
    color: #071491;
    margin-top: 10px;
  }
  .drawer-content {
    margin-top: 30px;
  }
  .kids-info-image-upload .ant-upload-list-item-thumbnail {
    width: 265px !important;
    height: auto !important;
  }
  .kids-info-image-upload .ant-upload-list-item-done {
    height: auto !important;
  }
  .form-reminder {
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.45);
  }
}
