#sales188ReleaseWrapper {
  .release-content-container {
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
  }

  .release-content-container > * {
    background-color: white;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
  }

  .ant-picker {
    width: 100%;
  }

  .submit-button {
    margin-left: 278px;
  }

  .text-blue {
    color: #071491;
  }
}
#exclamation-icon {
  color: #394cf6;
}
