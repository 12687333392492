.content-container {
  margin: 20px;
  background-color: #ffffff;
  padding: 24px 0;
}

.button-container {
  display: flex;
  align-items: center;
}
