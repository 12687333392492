#sales188CreateNotificationWrapper {
  position: relative;
  height: 100%;
  .sales-create-notification-content-container {
    background-color: white;
    padding: 20px;
  }
  .wrapper {
    padding: 20px 24px;
  }
  .ant-picker {
    width: 100%;
  }
  .form-reminder {
    margin-top: 1px;
    margin-left: 371px;
    color: rgba(0, 0, 0, 0.45);
    width: 100%;
  }
  .no-mb {
    margin-bottom: 0;
  }
  .divider-wrapper {
    display: flex;
    justify-content: center;
  }
  .divider {
    width: 650px;
  }
  .button {
    margin-left: 371px;
  }
  .warning {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    height: 40px;
    border-radius: 2px;
    border: 1px solid #a4adfb;
    background: #ebedfe;
    margin-right: 8px;
  }
  .footer {
    background-color: white;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    padding: 20px 24px;
    position: absolute;
    bottom: 0px;
    left: 0;
  }
}
