.ggl-content-container {
  padding: 24px;
}

.ggl-content-inner-container {
  background-color: #ffffff;
}

.ggl-content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}

.ggl-table-container {
  padding: 0 24px;
}

.ggl-preview-container {
  display: flex;
}

.ggl-preview-inner-container {
  margin-right: 48px;
}

.guide-icon {
  margin-left: 8px;
  color: #00b140;
}

.guide-icon-not-exist {
  margin-left: 8px;
  color: #f48120;
}

.ggl-form .ant-upload-list-item-name {
  color: #394cf6;
}
