.theme-tab-content-container {
  padding: 0 24px 24px;
}

.theme-tab-table-container {
  padding: 24px;
}

.theme-tab-header-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
}

.theme-upload .ant-upload-list-item {
  height: 138px !important;
}

.theme-upload .ant-upload-list-item-thumbnail {
  width: 120px !important;
  height: 120px !important;
}

#theme-form label {
  margin-bottom: 6px;
}
