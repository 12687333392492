.display-session-container {
  width: 548px;
  margin: auto;
  border-block-end: 1px solid rgba(5, 5, 5, 0.06);
}

.none-edit p {
  color: #071491;
}

.ant-upload-list-item-actions a {
  display: none;
}

.link {
  text-decoration-line: underline;
  color: #394CF6;
}