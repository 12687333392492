.region-tag-container {
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.region-tag-container p {
  font-size: 12px;
  padding: 0 8px;
  line-height: 20px;
}

.icon {
  width: 14px;
  height: 14px;
  margin-left: 2px;
}
