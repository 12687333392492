#graduationReviewedWrapper {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
  }

  .header-title {
    font-size: 20px;
    font-weight: 600;
  }

  .table-container {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
  }

  .select {
    display: flex;
    justify-content: space-between;
    align-items: last baseline;
    padding: 8px 0;
  }

  .flex {
    display: flex;
    margin-right: 40px;
  }

  .ant-select-selector {
    border: none !important;
  }

  .table-container > * {
    background-color: white;
  }

  .table {
    padding: 0 24px;
  }

  .warning-icon {
    width: 14px;
    height: 14px;
  }

  .deadline-container {
    display: flex;
    align-items: center;
  }

  .deadline-container img {
    margin-left: 5px;
  }

  .excellent-container {
    display: flex;
    align-items: center;
  }

  .trophy-icon {
    width: 14px;
    height: 14px;
    margin-left: 8px;
  }

  .total-container {
    text-align: end;
    padding: 20px 24px;
  }

  .progress-bar {
    display: flex;
    align-items: center;
  }

  .progress-bar-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }

  .unit {
    width: 14px;
    height: 8px;
    /* background-color: #F5F5F5; */
    margin-right: 2px;
  }

  .text-vertical-center {
    display: flex;
    align-items: center;
  }

  .content {
    margin-left: 8px;
  }
}
