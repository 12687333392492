.main-content-header-container {
  width: 100%;
  height: 102px;
  padding: 16px 24px;
}

.main-content-header-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
}

.main-content-header-title-container p {
  font-size: 20px;
  font-weight: 500;
  margin-left: 12px;
}
