#importantNoticeDetailWrapper {
  position: relative;
  height: 100%;
  .content-container {
    padding: 24px;
    display: flex;
  }
  .left {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .right {
    width: 45%;
  }
  .content {
    color: #071491;
  }
  .ant-form-item-control {
    width: 326px;
  }
  .form {
    width: 90%;
  }
  .emulator {
    width: 390px;
    background-color: #fef2e9;
    height: 100%;
    margin-left: 30px;
    padding: 30px;
    position: relative;
    overflow: hidden;
  }
  .emulator-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }
  .emulator-content {
    margin-top: 24px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .textarea {
    background-color: #fdfcf6;
    border-radius: 16px;
    width: 330px;
    height: 390px;
    padding: 20px;
    box-shadow: 2px 2px 8px 0px rgba(35, 45, 74, 0.1);
  }
  .icon {
    width: 150px;
    height: 150px;
    position: absolute;
    bottom: -55px;
    right: 10px;
  }
  .footer {
    background-color: white;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    padding: 20px 24px;
    position: absolute;
    bottom: 0px;
    left: 0;
  }
}
