#leadDetailWrapper {
  position: relative;
  .table-container > * {
    background-color: white;
  }
  .table-container {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
  }
  .flex {
    display: flex;
  }
  .vertical-center {
    align-items: center;
  }
  .justify-content-end {
    justify-content: end;
  }
  .justify-content-space-between {
    justify-content: space-between;
  }
  .block {
    padding: 24px 55px;
  }
  .title {
    font-weight: 700;
    font-size: 16px;
  }
  .w-32 {
    width: 32%;
  }
  .ant-form-item-label > label {
    color: rgba(0, 0, 0, 0.45);
  }
  .mt-20 {
    margin-top: 20px;
  }
  .gap-10 {
    gap: 10px;
  }
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}
#prospectInfoDrawerWrapper {
  .h-32px {
    height: 32px;
  }
  .address-modal {
    background-color: white;
    width: 400px;
    height: 600px;
    position: absolute;
    top: 100px;
    left: -500px;
  }
  .address-modal-2 {
    width: 400px;
    height: 544px;
  }
  .title {
    height: 56px;
    padding: 25px;
    border-bottom: 1px solid black;
  }
  .border-table {
    border: 1px solid red;
  }
  .warn-text {
    color: red;
    margin-top: 3px;
  }
  .onDelete-modal-button-group {
    display: flex;
    justify-content: flex-end;
  }
}
