.edit-notification-drawer .ant-drawer-header-title {
  flex-direction: row-reverse;
}

.edit-notification-drawer .ant-drawer-header-title button {
  margin-inline-end: 0;
}

p.one-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tab-content-container {
  padding: 12px 24px 24px;
}

.tab-content-inner-container {
  padding: 16px 24px;
}

.search-container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding-right: 20px;
  background-color: #ffffff;
  padding-top: 20px;
}

.search {
  width: 264px;
  margin-bottom: 12px;
}
