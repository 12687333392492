.event-detail p {
  line-height: 1.2;
}

.template-list-default .ant-form-item-control-input-content {
  border-left: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}

.template-list-template .ant-form-item-control-input-content {
  border: 1px solid #d9d9d9;
}

.template-list-default.ant-radio-group,
.template-list-default .ant-radio-wrapper {
  width: 100%;
}

.template-list-default .ant-radio-wrapper {
  border-bottom: 1px solid #d9d9d9;
  padding: 0 8px;
}

.template-list-template .ant-radio-wrapper {
  padding: 0 8px;
}
