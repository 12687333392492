.service-menu-container {
  z-index: 1;
  width: 48px;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-container {
  width: 48px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.service-icon {
  width: 24px;
  height: 24px;
}
