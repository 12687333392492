@import "tailwindcss/base";
@import "tailwindcss/utilities";

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Lexend", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  line-height: 1;
}

.notification-filter-container .ant-select-selector {
  background-color: #ebeff5 !important;
  border: none !important;
}

.ant-form-item-label {
  padding: 0 !important;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

:where(.css-dev-only-do-not-override-72yytv).ant-drawer .ant-drawer-close {
  margin-inline-end: 0px;
}

.ant-picker-month-panel .ant-picker-cell::before {
  top: 28%;
}

.ant-card-meta-title {
  font-weight: 400 !important;
  margin-bottom: 0 !important;
}

.ant-message-notice-error .ant-message-notice-content {
  background-color: #fde8e9 !important;
}

.ant-message-notice-success .ant-message-notice-content {
  background-color: #d5f2df !important;
}

/* override tailwindcss base */
button.ant-btn-primary {
  background-color: #394cf6;
}

/* custom antd table style */
.custom-table-style tbody td {
  vertical-align: middle;
}
.custom-table-style tbody td img {
  width: 112px;
  height: auto;
}
.custom-table-style tbody td img.custom-avatar {
  width: 44px;
  height: 44px;
}
.custom-table-style .ant-table-placeholder {
  /* Prevent the Dropdown from being obscured by the placeholder */
  z-index: 999 !important;
}

/* Prevent the content from being blocked by the DrawerFooter */
.lengthy-drawer .ant-drawer-body {
  padding-bottom: 64px;
}

/* Give the label a width that allows right alignment */
.form-align-center .ant-form-item-label {
  width: 160px;
}
.form-align-center .sub-form-item .ant-form-item-label {
  width: 80px;
}
