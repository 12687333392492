.main-content-footer-container {
  width: calc(100% - 270px);
  height: 72px;
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 24px;
  box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
  z-index: 999;
}

.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #a4adfb;
  background: #ebedfe;
  margin-right: 8px;
}
