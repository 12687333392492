.performer-tab-content-container {
  padding: 0 24px 24px;
}

.performer-tab-content-inner-container {
  padding: 12px 24px;
}

.performer-tab-content-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.ant-card:hover .overlay {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.performer-upload .ant-upload-list-item {
  height: 138px !important;
}

.performer-upload .ant-upload-list-item-thumbnail {
  width: 120px !important;
  height: 120px !important;
}
