#buyUpQueryWrapper {
  position: relative;
  .table-container > * {
    background-color: white;
  }
  .table-container {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
  }
  #buy-up-query-form {
    padding: 20px;
  }
}
