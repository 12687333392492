#sales188PushNotificationWrapper {
    .wrapper {
        z-index: 2;
        display: flex;
        flex-direction: column;
        padding: 20px 24px;
    }
    .sales-notification-content-container {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 20px;
    }
    .top-column {
        display: flex;
        justify-content: flex-end;
    }
    .search-box {
        width: 264px;
    }
    .button-group {
        display: flex;
    } 
}