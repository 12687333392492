.drawer-footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  height: 64px;
  position: fixed;
  right: 0;
  bottom: 0;
  box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
  padding: 0 24px;
  background-color: #ffffff;
  z-index: 1000;
}
