.account-container {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.account-container > p {
  margin-left: 8px;
}

.avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 100%;
}

.region-container {
  margin: 0 0 0 8px;
}

.arrow {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.sub-options-container {
  position: absolute;
  right: 0;
  top: 56px;
  width: 250px;
  border-radius: 2px;
  padding: 16px 0;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  background-color: white;
}

.sub-option-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 16px;
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
}

.sub-option-header-container p {
  font-size: 16px;
  font-weight: 500;
}

.sub-option-content-container {
  padding: 12px 24px 0;
}

.sub-option-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.sub-option-container p {
  line-height: 22px;
  cursor: pointer;
  margin-bottom: 12px;
}

.sub-option-logout-container {
  padding-top: 12px;
}
