#adExclusionListWrapper {
        .button-container {
            display: flex;
            align-items: center;
        }
        .content-container {
            margin: 20px;
            background-color: #ffffff;
            padding: 24px 0;
            display: flex;
            flex-direction: column;
            align-items: center
        }
        .title-container {
            width: 448px;
            padding: 20px 0;
        }
}