.permission-container {
  width: 100vw;
  height: calc(100vh - 60px);
  position: fixed;
  left: 0;
  top: 60px;
  z-index: 999;
  padding: 24px;
}

.permission-inner-container {
  width: 100%;
  max-height: 100%;
  padding: 0 0 24px;
  overflow: hidden;
}

.permission-header-container {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.permission-header-container p {
  font-size: 20px;
  font-weight: 500;
}

.permission-content-container {
  padding: 24px;
  overflow-y: scroll;
}
