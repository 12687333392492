$rhap_theme-color: #394CF6 !default;
$rhap_background-color: #EBEDFE !default;
$rhap_bar-color: #C7CDFC !default;
$rhap_time-color: #394CF6 !default;
$rhap_font-family: inherit !default;

#reviewGraduationCapWrapper {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 24px;
    position: sticky;
    top: 0;
    left: 0;
  }

  .header-title {
    font-size: 20px;
    font-weight: 600;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    padding: 20px 24px;
    position: sticky;
    bottom: 0;
    left: 0;
  }
  .container {
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
}
  .wrapper {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
  }
  .infoBoxes {
    display: flex;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 16px 0px;
  }

  .infoBoxes div {
    width: 180px;
    height: 70px;
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-bottom: 24px;
  }

  .sub-box {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }

  .content {
    color: #071491;
    margin-top: 10px;
  }
  .column {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    align-items: baseline;
  }
  .left-panel {
    width: 180px;
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
  }
  .right-panel {
    width: 722px;
  }
  .text-blue {
    color: #071491;
  }
  .between {
    display: flex;
    justify-content: space-between;
  }
  .align-self-start {
    align-self: flex-start;
  }
  .start {
    justify-content: flex-start;
  }
  .progress-bar {
    display: flex;
    align-items: center;
  }
  .progress-bar-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
  .unit {
    width: 14px;
    height: 8px;
    /* background-color: #F5F5F5; */
    margin-right: 2px;
  }
  .text-vertical-center {
    display: flex;
    align-items: center;
  }
  .ant-checkbox-wrapper span {
    display: flex;
    align-items: center;
  }
  .form {
    background-color: #fafafa;
  }
  .ant-form-item-label {
    flex: 0 0 120px;
    margin-left: 10px;
  }
  .link {
    text-decoration: underline;
  }
    .rhap_container {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      line-height: 1;
      font-family: $rhap_font-family;
      padding: 10px 15px 10px 40px;
      background-color: $rhap_background-color;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
      position: relative;
      width: 300px;
  
      &:focus:not(:focus-visible) {
        outline: 0;
      }
  
      svg {
        vertical-align: initial; // overwrite Bootstrap default
      }
    }
  
    .rhap_header {
      margin-bottom: 10px;
    }
  
    .rhap_footer {
      margin-top: 5px;
    }
  
    .rhap_main {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }
  
    .rhap_horizontal {
      flex-direction: row;
  
      .rhap_controls-section {
        margin-left: 8px;
      }
    }
  
    .rhap_horizontal-reverse {
      flex-direction: row-reverse;
  
      .rhap_controls-section {
        margin-right: 8px;
      }
    }
  
    .rhap_stacked-reverse {
      flex-direction: column-reverse;
  
      .rhap_controls-section {
        margin-bottom: 8px;
      }
    }
  
    .rhap_progress-section {
      display: flex;
      flex: 3 1 auto;
      align-items: center;
    }
  
    .rhap_progress-container {
      display: flex;
      align-items: center;
      height: 20px;
      flex: 1 0 auto;
      align-self: center;
      margin: 0 calc(10px + 1%);
      cursor: pointer;
      user-select: none;
      -webkit-user-select: none;
  
      &:focus:not(:focus-visible) {
        outline: 0;
      }
    }
  
    .rhap_time {
      color: $rhap_time-color;
      font-size: 16px;
      user-select: none;
      -webkit-user-select: none;
    }
  
    .rhap_progress-bar {
      box-sizing: border-box;
      position: relative;
      z-index: 0;
      width: 100%;
      height: 5px;
      background-color: $rhap_bar-color;
      border-radius: 2px;
    }
  
    .rhap_progress-filled {
      height: 100%;
      position: absolute;
      z-index: 2;
      background-color: $rhap_theme-color;
      border-radius: 2px;
    }
  
    .rhap_progress-bar-show-download {
      background-color: rgba($rhap_bar-color, 0.5);
    }
  
    .rhap_download-progress {
      height: 100%;
      position: absolute;
      z-index: 1;
      background-color: $rhap_bar-color;
      border-radius: 2px;
    }
  
    .rhap_progress-indicator {
      box-sizing: border-box;
      position: absolute;
      z-index: 3;
      width: 20px;
      height: 20px;
      margin-left: -10px;
      top: -8px;
      background: $rhap_theme-color;
      border-radius: 50px;
      box-shadow: rgba($rhap_theme-color, .5) 0 0 5px;
    }
  
    .rhap_controls-section {
      display: flex;
      flex: 1 1 auto;
      justify-content: space-between;
      align-items: center;
    }
  
    .rhap_additional-controls {
      display: none;
      flex: 1 0 auto;
      align-items: center;
    }
  
    .rhap_repeat-button {
      font-size: 26px;
      width: 26px;
      height: 26px;
      color: $rhap_theme-color;
      margin-right: 6px;
    }
  
    .rhap_main-controls {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .rhap_main-controls-button {
      margin: 0 3px;
      color: $rhap_theme-color;
      font-size: 35px;
      width: 35px;
      height: 35px;
    }
  
    .rhap_play-pause-button {
      font-size: 24px;
      width: 24px;
      height: 24px;
      position: absolute;
      left: 5px;
      top: 8px;
    }
  
    .rhap_volume-controls {
      display: none;
      flex: 1 0 auto;
      justify-content: flex-end;
      align-items: center;
    }
  
    .rhap_volume-button {
      flex: 0 0 26px;
      font-size: 26px;
      width: 26px;
      height: 26px;
      color: $rhap_theme-color;
      margin-right: 6px;
    }
  
    .rhap_volume-container {
      display: flex;
      align-items: center;
      flex: 0 1 100px;
      user-select: none;
      -webkit-user-select: none;
    }
  
    .rhap_volume-bar-area {
      display: flex;
      align-items: center;
      width: 100%;
      height: 14px;
      cursor: pointer;
  
      &:focus:not(:focus-visible) {
        outline: 0;
      }
    }
  
    .rhap_volume-bar {
      box-sizing: border-box;
      position: relative;
      width: 100%;
      height: 4px;
      background: $rhap_bar-color;
      border-radius: 2px;
    }
  
    .rhap_volume-indicator {
      box-sizing: border-box;
      position: absolute;
      width: 12px;
      height: 12px;
      margin-left: -6px;
      left: 0;
      top: -4px;
      background: $rhap_theme-color;
      opacity: 0.9;
      border-radius: 50px;
      box-shadow: rgba($rhap_theme-color, .5) 0 0 3px;
      cursor: pointer;
  
      &:hover {
        opacity: .9;
      }
    }
  
    .rhap_volume-filled {
      height: 100%;
      position: absolute;
      z-index: 2;
      background-color: $rhap_theme-color;
      border-radius: 2px;
    }

    .rhap_forward-button, .rhap_rewind-button {
      display: none;
    }

  
    /* Utils */
    .rhap_button-clear {
      background-color: transparent;
      border: none;
      padding: 0;
      overflow: hidden;
      cursor: pointer;
  
      &:hover {
        opacity: .9;
        transition-duration: .2s;
      }
  
      &:active {
        opacity: .95;
      }
  
      &:focus:not(:focus-visible) {
        outline: 0;
      }
    }
}
