#graduationDrawerWrapper {
    .content {
            color: #071491;
            margin-top: 10px;
    }
    .text-vertical-center {
    display: flex;
    align-items: center;
    }
        .progress-bar {
            display: flex;
            align-items: center;
        }
    
        .progress-bar-container {
            display: flex;
            justify-content: flex-start;
            gap: 10px;
        }
    
        .unit {
            width: 14px;
            height: 8px;
            /* background-color: #F5F5F5; */
            margin-right: 2px;
        }
        .drawer-content {
            margin-top: 30px;
        }
        .process-item {
            display: flex;
            flex-direction: column;
            margin-top: 8px;
            width: 430px;
            min-height: 60px;
            border: 1px solid rgba(0, 0, 0, 0.06);
            border-radius: 2px;
            padding: 8px;
        }
        .row-container {
            display: flex;
            justify-content: space-between;
             align-items: center;
        }
        .display-none {
            opacity: 0;
        }
        .left-part {
            display: flex;
            align-items: center;
        }
        .right-part {
            display: flex;
            align-items: center;
            gap: 8px;
        }
        .number-icon {
            width: 20px;
            height: 20px;
        }
        .item-info {
            margin-left: 8px;
            display: flex;
            flex-direction: column;
        }
        .item-info-date {
            color: rgba(0, 0, 0, 0.45)
        }
        .pass {
            border: 1px solid #80D8A0;
            border-radius: 2px;
            background-color: #D5F2DF;
            color: #00762B;
            padding: 1px 8px
        }
        .under-review {
            border: 1px solid #D9D9D9;
            border-radius: 2px;
            background-color: #FAFAFA;
            padding: 1px 8px
        }
        .retry {
            border: 1px solid #FBCDA6;
            border-radius: 2px;
            background-color: #FEF2E9;
            color: #F48120;
            padding: 1px 8px
        }
}