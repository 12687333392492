#getWinnerWrapper {
  position: relative;
  .table-container > * {
    background-color: white;
  }
  .table-container {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
  }
  .form {
    padding: 20px;
  }
  .item {
    width: 400px;
  }
}
