#sales188DevWrapper {
  .dev-content-container {
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
  }
  .dev-content-container > * {
    background-color: white;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
  }
  .ant-picker {
    width: 100%;
  }
  .submit-button {
    margin-left: 278px;
  }
}
