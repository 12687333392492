.service-sub-menu-container {
  width: 220px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  border-left: 1px solid #f0f0f0;
}

.service-sub-menu-container > p {
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  padding: 0 12px;
}

.ant-layout-sider {
  height: 100%;
  background: #ffffff !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15) !important;
}

.ant-layout-sider-zero-width-trigger {
  position: relative;
  transform: perspective(300px) rotateY(80deg) translateX(8px);
  background: white !important;
  box-shadow: 8px 0px 8px 0px rgba(0, 0, 0, 0.15);
  width: 70px !important;
  height: 53px !important;
  top: 250px !important;
}
.ant-layout-sider-zero-width-trigger img {
  transform: rotateX(80deg) scale(5);
}

.ant-layout-sider-children ul {
  border: none !important;
  position: relative;
  z-index: 1;
}
