#leadRegistrationWrapper {
  position: relative;
  .content-container > * {
    background-color: white;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  .no-icon {
    width: 64px;
    height: 70px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    background-color: white;
    flex-direction: row;
    align-items: center;
    padding: 20px 24px;
    position: sticky;
    bottom: 0;
    left: 0;
  }
  .red-text {
    color: red;
  }
  .expand-icon {
    width: 12px;
    height: 12px;
    border: 1px solid gray;
  }
  .result-icon {
    width: 80px;
    height: 80px;
  }
}
