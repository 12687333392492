.login-content-container {
  width: 100%;
  height: calc(100vh - 60px);
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login-footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 16px;
}

.login-footer-container p {
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}

.login-container {
  width: 480px;
  padding: 48px 40px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.login-logo-container {
  width: 86px;
  height: 40px;
}

.login-logo-container img {
  width: 100%;
  height: auto;
}

.login-title {
  font-size: 38px;
  font-weight: 600;
  margin: 64px 0 48px;
  text-align: center;
}

.login-title span {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.login-sub-title {
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 16px;
  font-size: 16px;
}
