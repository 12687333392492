#mediaCodeWrapper {
  position: relative;
  .content-container > * {
    background-color: white;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  .reminder-text {
    margin: -16px 0 24px;
    color: rgba(0, 0, 0, 0.45);
  }
}
