.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
}

.header-title {
  font-size: 20px;
  font-weight: 600;
}
