#DemoAppTagTabWrapper {
  .table-container {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
  }
  .select-total {
    display: flex;
    justify-content: space-between;
    align-items: last baseline;
    padding: 8px 0;
  }
  .table-container > * {
    background-color: white;
  }
  .total-container {
    text-align: end;
    padding: 20px 24px;
  }
  .create-button {
    margin-left: 15px;
    margin-right: 15px;
  }
  .dot {
    border-radius: 50%;
    height: 6px;
    margin-right: 8px;
    width: 6px;
  }
  .in-use {
    background-color: #394cf6;
  }
  .not-in-use {
    background-color: #d9d9d9;
  }
  .in-use-container {
    align-items: center;
    display: flex;
  }
  .no-pt {
    padding-top: 0;
  }
  .edit-button {
    margin-right: 16px;
    padding: 0;
  }
  .delete-button {
    padding: 0;
  }
}

#tagDrawerWrapper {
  .content {
    color: #071491;
    margin-top: 10px;
  }
  .text-vertical-center {
    display: flex;
    align-items: center;
  }
  .form-item {
    margin-top: 20px;
  }
  .reminder-text {
    margin: -16px 0 24px;
    color: rgba(0, 0, 0, 0.45);
  }
}
