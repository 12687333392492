#advisorRegionWrapper {
  position: relative;
  .content-container > * {
    background-color: white;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
  }
  .tabs {
    height: 610px;
  }
  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  .selected-row {
    background-color: #ebedfe;
  }
}

.cascader {
  width: 100%;
  height: 300px;
}
.ant-cascader-menu {
  width: 160px;
}
