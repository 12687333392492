#calendarWrapper {
  position: relative;
  .content-container > * {
    background-color: white;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
  }
  .short-select {
    width: 100px;
  }
  .long-select {
    width: 250px;
  }
}
#eventDrawerWrapper {
  .content {
    color: #071491;
  }
}
