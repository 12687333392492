.announcement-table tbody td {
  vertical-align: middle;
}

.announcement-table tbody td img {
  width: 220px;
  height: auto;
}

.in-use-container {
  display: flex;
  align-items: center;
}

.form-reminder {
  margin-bottom: 24px;
  color: rgba(0, 0, 0, 0.45);
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
}

.announcement-image-upload .ant-upload-list-item-thumbnail {
  width: 265px !important;
  height: auto !important;
}

#announcement-form label {
  margin-bottom: 6px;
}

.announcement-image-upload .ant-upload-list-item-done {
  height: auto !important;
}

.onDelete-modal-button-group {
  display: flex;
  justify-content: flex-end;
}
