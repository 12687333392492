.banner-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner-upload .ant-upload-list-item-thumbnail,
.banner-upload .ant-upload-list-item-thumbnail img {
  height: 48px !important;
  width: auto !important;
}

.batch-container .ant-modal-content {
  padding: 20px 0px 10px;
}

.batch-container .ant-modal-footer {
  border-top: 1px solid #f0f0f0;
  padding: 10px 24px 0;
}

.batch-list-container .ant-card-body {
  padding: 0;
}
