.header-container {
  z-index: 999;
  width: 100vw;
  height: 60px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.logo {
  width: 150px;
  height: auto;
  cursor: pointer;
}
