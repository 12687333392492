#kidsInfoWrapper {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
  }

  .header-title {
    font-size: 20px;
    font-weight: 600;
  }

  .table-container {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
  }
  .select {
    display: flex;
    justify-content: space-between;
    align-items: last baseline;
    padding: 8px 0;
  }

  .ant-select-selector {
    border: none !important;
  }

  .table-container > * {
    background-color: white;
  }

  .table {
    padding: 0 24px;
  }

  .warning-icon {
    width: 14px;
    height: 14px;
  }

  .deadline-container {
    display: flex;
    align-items: center;
  }

  .deadline-container img {
    margin-left: 5px;
  }

  .total-container {
    text-align: end;
    padding: 20px 24px;
  }

  .action-box {
    display: flex;
  }
  .p0 {
    padding: 5px;
    padding-left: 0;
  }
  .modal-button-group {
    display: flex;
  }
  .tag-group {
    display: flex;
    gap: 8px;
  }
  .no-text {
    opacity: 0.25;
  }
  .topright-container {
    display: flex;
    gap: 10px;
    margin-right: 24px;
  }
}
