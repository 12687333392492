.section-container {
  padding: 24px;
  margin-bottom: 24px;
}

.section-title-container {
  height: 60px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
}

.section-title-container p {
  font-size: 16px;
  font-weight: 500;
}

.section-content-container {
  padding: 24px 0;
}
