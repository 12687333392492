#campaignIdWrapper {
  .content-container {
    margin: 20px;
    background-color: #ffffff;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ant-form-item-label {
    width: 170px;
    text-align: end;
  }
  .ant-form-item-control {
    width: 480px;
  }
  .generate-button {
    margin-left: 170px;
  }
  .id-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .id-box p {
    line-height: 1.5rem;
    width: 80%;
    word-wrap: break-word;
  }
  .id-box img {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
  .qrcode-box {
    display: flex;
    justify-content: flex-start;
  }
  .text-blue {
    color: #071491;
  }
  .qrcode {
    width: 104px;
    height: 104px;
    padding: 1px;
    border: 1px solid #d9d9d9;
  }
  .download-button {
    margin-left: 6px;
    align-self: self-end;
  }
  .d-none {
    display: none;
  }
}
