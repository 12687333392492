#sales188ViewNotificationDetailWrapper {
  position: relative;
  height: 100%;
  .sales-notification-detail-content-container {
    background-color: white;
    padding: 20px;
  }
  .wrapper {
    padding: 20px 24px;
  }
  .content {
    color: #071491;
    line-height: 2rem;
  }
  .footer {
    background-color: white;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    padding: 20px 24px;
    position: absolute;
    bottom: 0px;
    left: 0;
  }
}
