#leadsDetailDownloadWrapper {
    .button-container {
        display: flex;
        align-items: center;
        width: 480px;
    }

    .content-container {
        margin: 20px;
        background-color: #ffffff;
        padding: 24px 0;
        display: flex;
        flex-direction: column;
        align-items: center
    }

    .title-container {
        width: 448px;
        padding: 20px 0;
    }

    .text-container {
        width: 283px;
        line-height: 1.5rem;
    }

    .row { 
        display: flex;
    }

    .date-range-picker {
        width: 480px;
    }

    .spacer {
        width: 137px;
    }

    .market-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-right: 50px;
        margin-bottom: 30px;
    }
}