#leadListWrapper {
  position: relative;
  .table-container > * {
    background-color: white;
  }
  .table-container {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
  }
  #lead-inquiry-form {
    padding: 20px;
  }
  .ant-form-item {
    width: 272px;
    margin-left: 8px;
    margin-bottom: 0;
  }
  .form-button-left {
    margin-left: 40px;
  }
  .form-button-right {
    margin-left: 8px;
  }
  .flex {
    display: flex;
  }
  .vertical-center {
    align-items: center;
  }
  .justify-content-end {
    justify-content: end;
  }
  .justify-content-space-between {
    justify-content: space-between;
  }
  .table-inner-wrapper {
    padding: 20px;
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .gray-text {
    color: rgba(0, 0, 0, 0.25);
  }
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  .count-text {
    align-self: center;
    margin-left: 10px;
  }
}
